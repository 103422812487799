import React, { Component } from "react";
import "./VfiInputText.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faCheck, faTimes as faTimes2, faCaretUp, faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { faSearch, faTimes } from "@fortawesome/pro-light-svg-icons";
import { slugify, twoDigits } from "../helperFunctions";

class VfiInputText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessages: [],
    };

    this.ref = React.createRef();

    this.keyHandle = this.keyHandle.bind(this);
  }

  componentDidMount() {
    this.errorCheck(this.props.value ? this.props.value : this.props.defaultValue ? this.props.defaultValue : "");

    if (this.props.onMount) this.props.onMount(this.props.inputRef);
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.errorCheck(this.props.value ? this.props.value : this.props.defaultValue ? this.props.defaultValue : "");
    }
  }

  thisChange(e) {
    this.errorCheck(e.target.value);
  }

  errorCheck(value) {
    let errors = [];
    if (this.props.errorCheckSequence) {
      this.props.errorCheckSequence.forEach((element) => {
        // If a function has been passed...
        let error = "";
        if (typeof element === "function") {
          error = element(value);
        } else if (typeof element === "object") {
          // If an object has passed, we expect the following structure:
          // {function:validationFunction(), args:[argument1, argument2...]}
          error = element.function(value, ...element.args);
        }

        if (error !== "" && error !== undefined) {
          errors.push(error);
        }
      });
    }
    this.setState({ errorMessages: errors });
  }

  keyHandle(e) {
    if (e.keyCode === 27) {
      if (this.props.isSearch) this.props.onChange({ target: { value: "" } });
    }
    if (e.keyCode === 13 && this.props.onEnter) {
      this.props.onEnter(e);
    }
    if (e.keyCode === 27 && this.props.onEscape) {
      this.props.onEscape(e);
    }
  }

  numberChange(addition) {
    const inputElement = this.ref.current;
    if (inputElement) {
      // Calculate the new value
      let value = inputElement.value;
      if (isNaN(value) || value === "") {
        value = 0;
      }
      value = parseInt(value) + addition;
      const { min, max } = this.props;
      if (min !== undefined) {
        value = Math.max(value, min);
      }
      if (max !== undefined) {
        value = Math.min(value, max);
      }

      // Set the input value
      inputElement.value = value;

      // Manually trigger the onChange event
      const event = new Event("input", { bubbles: true });
      inputElement.dispatchEvent(event);

      // Call the onChange handler manually
      this.onChangeFunc({ target: inputElement });
    }
  }

  onChangeFunc = (e) => {
    if (this.props.inputType !== undefined) {
      if (this.props.inputType.toLowerCase() === "int") e.target.value = e.target.value.replace(/\D/, "");

      if (this.props.inputType.toLowerCase() === "2digits") {
        e.target.value = twoDigits(e);
      }

      if (this.props.inputType.toLowerCase() === "slug") {
        e.target.value = slugify(e.target.value);
      }
    }

    if (this.props.maxLength !== undefined) {
      e.target.value = e.target.value.substring(0, this.props.maxLength);
    }

    this.thisChange(e);
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  render() {
    const {
      hideError,
      checkShow,
      style,
      className,
      inputRef,
      name,
      type,
      value,
      defaultValue,
      disabled,
      onBlur,
      onFocus,
      placeholder,
      onKeyUp,
      isSearch,
      customIcon,
      onChange,
      upDownArrows,
      customRightSide,
      customUnder,
      maxLength,
      onSubmit,
    } = this.props;
    const inputError = this.state.errorMessages.length > 0 ? true : false;
    const inputClassName = inputError ? (hideError ? "" : "error") : checkShow ? "check" : "";

    return (
      <div style={style} className={"vfi-input-text " + (className ?? "")}>
        <label>
          <input
            ref={inputRef ?? this.ref}
            name={name}
            type={type ?? "text"}
            value={value}
            defaultValue={defaultValue}
            disabled={disabled}
            onChange={this.onChangeFunc}
            onBlur={(e) => {
              if (onBlur) onBlur(e);
            }}
            onFocus={(e) => {
              if (onFocus) onFocus(e);
            }}
            className={inputClassName}
            placeholder={placeholder ? placeholder : ""}
            onKeyDown={this.keyHandle}
            onKeyUp={onKeyUp ?? (() => {})}
          />
          {value === "" || !isSearch ? (
            <div className="right-icon">
              {inputError && !hideError ? (
                <FontAwesomeIcon icon={faExclamation} />
              ) : checkShow ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : isSearch ? (
                <FontAwesomeIcon icon={faSearch} />
              ) : (
                ""
              )}
              {customIcon && customIcon}
            </div>
          ) : (
            <div
              className="remove-text-button"
              onClick={() => {
                onChange({ target: { value: "" } });
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          )}
          {upDownArrows && (
            <div className="up-down-arrows">
              <button className="up-arrow" onClick={() => this.numberChange(1)}>
                <FontAwesomeIcon icon={faCaretUp} />
              </button>
              <button className="down-arrow" onClick={() => this.numberChange(-1)}>
                <FontAwesomeIcon icon={faCaretDown} />
              </button>
            </div>
          )}
          {customRightSide && <div className="custom-right-side">{customRightSide}</div>}
          {customUnder && <div className="custom-under">{customUnder}</div>}
          {maxLength ? (
            <div className="max-chars">
              {value.length}/{maxLength}
            </div>
          ) : (
            ""
          )}
          {onSubmit && (
            <div className="submit-buttons">
              <button
                className="yes"
                onClick={() => {
                  onSubmit({
                    target: (inputRef ?? this.ref)?.current,
                    checked: true,
                  });
                }}
              >
                <FontAwesomeIcon icon={faCheck} />
              </button>
              <button
                className="no"
                onClick={() => {
                  onSubmit({
                    target: (inputRef ?? this.ref)?.current,
                    checked: false,
                  });
                }}
              >
                <FontAwesomeIcon icon={faTimes2} />
              </button>
            </div>
          )}
          <div className="errors">
            {this.state.errorMessages.map((element, i) => {
              return !hideError ? (
                <div className="error" key={i}>
                  {element}
                </div>
              ) : (
                ""
              );
            })}
          </div>
        </label>
      </div>
    );
  }
}

export default VfiInputText;
