import React, { Component } from "react";
import { faSortAlt } from "@fortawesome/pro-solid-svg-icons";
import { faSortAlt as faSortAltSorted } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import helperfunctions from "../../../assets/helperFunctions";
import env from "../../../environment.json";
import doodles from "../../../assets/images/profile doodles";
import { GlobalToolTipController } from "../../ToolTips/GlobalToolTip";

class UsersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortedBy: "name",
      reverse: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.site !== this.props.site) {
      this.setState({ sortedBy: "name" });
    }
  }

  checkboxFiltering(list, locateAttr) {
    //Filter the list when checkbox in filter changes

    const filter = this.props.sitesChecked;
    let tempList = [];

    if (filter.includes("all") || filter.length === 0) {
      //If show all or if we are filtering wrong data return all
      // tempList = tempList.concat(list);
      tempList = list;
    } else {
      if (locateAttr.split(".").length > 1) {
        //If we have to filter for ex list.permission.permission
        var attrArray = locateAttr.split(".");
        var attr1 = attrArray[0];
        var attr2 = attrArray[1];
        filter.forEach((element) => {
          const filteredList = list.filter((q) => q[attr1][attr2] === element);
          tempList = tempList.concat(filteredList);
        });
      } else {
        filter.forEach((element) => {
          const filteredList = list.filter((q) => q[locateAttr] === element);
          tempList = tempList.concat(filteredList);
        });
      }
    }
    return tempList;
  }

  render() {
    let searchContent = helperfunctions.searchSeparateWordsFilter(this.props.userContents, this.props.searchValue);
    searchContent = this.checkboxFiltering(searchContent, "permission.permission");

    return (
      <div className="users">
        <div className="user-list">
          <table className="user-table">
            <thead>
              <tr>
                <th
                  className={this.state.sortedBy === "name" ? "name sorted-by" : "name"}
                  onClick={() => {
                    helperfunctions.sortList("firstname", searchContent, this.state.sortedBy === "name");
                    let reverse = false;
                    if (this.state.sortedBy === "name" && this.state.reverse === false) reverse = true;
                    this.setState({ sortedBy: "name", reverse: reverse });
                  }}
                >
                  <GlobalToolTipController
                    offset={{ x: 15, y: 30 }}
                    title=""
                    delay={600}
                    toolTipElements={<div>Sort by Name</div>}
                  >
                    Name
                    <FontAwesomeIcon
                      icon={this.state.sortedBy === "name" ? faSortAltSorted : faSortAlt}
                      flip={"vertical"}
                      transform={this.state.reverse ? {} : { rotate: 180 }}
                    />
                  </GlobalToolTipController>
                </th>
                <th
                  className={this.state.sortedBy === "company" ? "company sorted-by" : "company"}
                  onClick={() => {
                    helperfunctions.sortList("company", searchContent, this.state.sortedBy === "company");
                    let reverse = false;
                    if (this.state.sortedBy === "company" && this.state.reverse === false) reverse = true;
                    this.setState({ sortedBy: "company", reverse: reverse });
                  }}
                >
                  <GlobalToolTipController
                    offset={{ x: 15, y: 30 }}
                    title=""
                    delay={600}
                    toolTipElements={<div>Sort by Company</div>}
                  >
                    Company
                    <FontAwesomeIcon
                      icon={this.state.sortedBy === "company" ? faSortAltSorted : faSortAlt}
                      flip={"vertical"}
                      transform={this.state.reverse ? {} : { rotate: 180 }}
                    />
                  </GlobalToolTipController>
                </th>
                <th
                  className={this.state.sortedBy === "role" ? "role sorted-by" : "role"}
                  onClick={() => {
                    helperfunctions.sortList("permission.permission", searchContent, this.state.sortedBy === "role");
                    let reverse = false;
                    if (this.state.sortedBy === "role" && this.state.reverse === false) reverse = true;
                    this.setState({ sortedBy: "role", reverse: reverse });
                  }}
                >
                  <GlobalToolTipController
                    offset={{ x: 15, y: 30 }}
                    title=""
                    delay={600}
                    toolTipElements={<div>Sort by Role</div>}
                  >
                    Role
                    <FontAwesomeIcon
                      icon={this.state.sortedBy === "role" ? faSortAltSorted : faSortAlt}
                      flip={"vertical"}
                      transform={this.state.reverse ? {} : { rotate: 180 }}
                    />
                  </GlobalToolTipController>
                </th>

                <th
                  className={this.state.sortedBy === "activity" ? "activity sorted-by" : "activity"}
                  onClick={() => {
                    helperfunctions.sortList("last_login_date", searchContent, this.state.sortedBy === "activity");
                    let reverse = false;
                    if (this.state.sortedBy === "last_login_date" && this.state.reverse === false) reverse = true;
                    this.setState({ sortedBy: "activity", reverse: reverse });
                  }}
                >
                  <GlobalToolTipController
                    offset={{ x: 15, y: 30 }}
                    title=""
                    delay={600}
                    toolTipElements={<div>Sort by Activity</div>}
                  >
                    Activity
                    <FontAwesomeIcon
                      icon={this.state.sortedBy === "activity" ? faSortAltSorted : faSortAlt}
                      flip={"vertical"}
                      transform={this.state.reverse ? {} : { rotate: 180 }}
                    />
                  </GlobalToolTipController>
                </th>
              </tr>
            </thead>
            {this.mapUsers(searchContent)}
          </table>
        </div>
      </div>
    );
  }

  filterClose(name, data) {
    window.location.hash = "/users/" + data.userId;
  }

  userImage(id, imgId, ext) {
    if ([null, undefined].includes(imgId)) {
      return doodles[id % 12];
    } else {
      return env.protocol + env.env + "/uploads/" + imgId + "_thumbnail." + ext;
    }
  }

  mapUsers(searchContent) {
    var usersList = searchContent.map((user, i) => {
      return (
        <tbody key={i} onClick={() => this.filterClose("userEdit", Object.assign({}, user))}>
          <tr>
            <td className="name">
              <div className="name-name">
                <img
                  src={this.userImage(user.userId, user.localImg_file_name, user.localImg_extension)}
                  alt="user-img"
                />
                {user.firstname + " " + user.lastname}
              </div>
            </td>
            <td className="company">
              <div className="company-name">{user.company}</div>
            </td>
            <td className="login">
              <div className="login-name">{user.permission.permission}</div>
            </td>
            <td className="login">
              <div className="login-name">{user.last_login}</div>
            </td>
          </tr>
        </tbody>
      );
    });

    return usersList;
  }
}
export default UsersList;
