import styles from "./CustomLevel3Link.module.scss";
import "./CustomLevel3Link.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VfiCKeditor from "src/assets/VfiCKeditor/VfiCKeditor";
import { TLangs } from "src/types";

interface objectProp {
  text: string;
  type: string;
}

export const customLevel3LinkObj = (): objectProp => ({
  text: "<h4 class='excerpt'><a href='https://visitfaroeislands.com'><i>Example link</i></a></h4>",
  type: "custom_level_3_link",
});

interface props {
  object: objectProp;
  change: (key: string, value: string) => void;
  openOverlay: (whatOverlay: string, data: any) => void;
  setOptionsList: (menu: JSX.Element | any, pos: any, isCustom: boolean) => void;
  close: () => void;
  language: TLangs;
}
export default function CustomLevel3Link(props: props) {
  console.log(props);
  const {
    change,
    object: { text },
  } = props;
  return (
    <div className={`edit-module custom-level-3-link ${styles["custom-level-3-link"]}`}>
      <div className={styles.arrow}>
        <FontAwesomeIcon icon={faArrowRight as IconProp} />
      </div>{" "}
      <VfiCKeditor
        className={styles["ck-editor"]}
        value={text}
        onChange={(content: string) => {
          change("text", content);
        }}
      />
    </div>
  );
}
