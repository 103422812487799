import { useState } from "react";
import styles from "./help.module.scss";
import Spinner from "src/Components/OverlayElements/Elements/Spinner/Spinner";
import Loading from "src/Components/ui/Loading/Loading";

export default function Help() {
  const [loading, setLoading] = useState(true);

  return (
    <div className={styles.container}>
      {loading && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
      <iframe
        src="https://visitfaroeislands.com/en/vfibackend-help/"
        width="100%"
        height="100%"
        style={{
          display: loading ? "none" : "block",
        }}
        onLoad={(e) => {
          e.preventDefault();

          setLoading(false);
        }}
        onLoadedData={(e) => {
          e.preventDefault();

          setLoading(false);
        }}
      ></iframe>
    </div>
  );
}
