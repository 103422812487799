import "./StaticPalette.scss";
import noImage from "../../../../../../assets/images/empty.jpg";
import VfiInputText from "../../../../../../assets/VfiInputText/VfiInputText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faLink, faTimes } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { TLangs, TLangsPartialObj, LANGUAGES } from "src/types";

const languageArrToObj = () => {
  return Object.values(LANGUAGES).reduce((accumulator, item) => {
    accumulator[item] = "";
    return accumulator;
  }, {} as TLangsPartialObj<string>);
};

interface objectProp {
  image: string;
  title: TLangsPartialObj<string>;
  href: TLangsPartialObj<string>;
  type: string;
}
export const staticPaletteObj = (): objectProp => ({
  image: "",
  title: languageArrToObj(),
  href: languageArrToObj(),
  type: "static_palette",
});

interface props {
  object: objectProp;
  change: (key: string, value: string) => void;
  openOverlay: (whatOverlay: string, data: any) => void;
  setOptionsList: (menu: JSX.Element | any, pos: any, isCustom: boolean) => void;
  close: () => void;
  language: TLangs;
}

export default function StaticPalette(props: props) {
  const { object, change, openOverlay, setOptionsList, close, language } = props;
  const optionsMenu = (
    <div key={object.href[language]} className="edit-module static-palette-options">
      <div>
        <div id="handle">
          <FontAwesomeIcon icon={faEllipsisV as IconProp} />
          <FontAwesomeIcon icon={faEllipsisV as IconProp} />
        </div>
        <div
          onClick={() => {
            close();
          }}
          className="close"
        >
          <FontAwesomeIcon icon={faTimes as IconProp} />
        </div>
      </div>
      <div className="top">
        <div className="top-choice active">Options</div>
      </div>
      <div className="content">
        <div className="option">
          <div className="label">Link</div>
          <div className="value">
            <VfiInputText
              defaultValue={object.href[language]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                change(`href.${language}`, e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="static-palette image-palette palette">
      <div
        className="image"
        onClick={() => {
          openOverlay("mediachoose", {
            filter: { mediaType: ["images", "videos"] },
            singleMedia: true,
            afterFunc: (e: any) => {
              if (e) {
                change("image", e.medium.large);
              }
              // object.image = e.medium.large;
            },
          });
        }}
      >
        <img src={object.image ? object.image : noImage} alt="palette" />
      </div>
      <VfiInputText
        className="title"
        defaultValue={object.title[language]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          change(`title.${language}`, e.target.value);
        }}
      />
      <div
        className="edit-module"
        onClick={(e) => {
          setOptionsList(optionsMenu, { x: e.pageX, y: e.pageY }, true);
        }}
      >
        <FontAwesomeIcon icon={faLink as IconProp} />
      </div>
    </div>
  );
}
