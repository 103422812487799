import React, { Component } from "react";
import env from "../../environment.json";
import "./Login.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faUser } from "@fortawesome/pro-solid-svg-icons";
import FacebookLogin from "react-facebook-login";
import vfiLogo from "../../assets/images/vfi-logo-lighter.png";
import vitaLogo from "../../assets/images/vita-logo.png";
import CreateWhatson from "./CreateWhatson";
import SubmitChange from "./submitChange";
import globalObject from "../../assets/globalVariables";
import ForgotPassword from "./ForgotPassword";
import NewPassword from "./NewPassword";
import RegisterTable from "./RegisterTable";
import axios from "axios";
import LoginOrRegister from "./LoginOrRegister";
import NewsletterUnsubscribe from "./NewsletterUnsubscribe";
import { modulesConfig } from "src/utils";
import getHashParams from "src/utils/getHashParams";
import { routerStore, useRouterStore } from "../Router/routerStore";
import VfiCheckbox from "src/assets/VfiCheckbox";

class Login extends Component {
  constructor(props) {
    super(props);

    const storedLoginInfo = localStorage.getItem("login_info");
    const loginInfo = { email: "", psw: "" };
    if (storedLoginInfo) {
      const storedLoginObj = JSON.parse(storedLoginInfo);
      loginInfo.email = storedLoginObj.email ?? "";
      loginInfo.psw = storedLoginObj.psw ?? "";
    }

    this.loginInfo = {
      email: "",
      psw: "",
      facebookLogin: undefined,
      img: "",
    };
    this.axios = require("axios");
    this.state = {
      loading: false,
      emailHasValue: false,
      passwordHasValue: false,
      showPassword: false,
      rememberMe: false,
      navTo: "",
      urlToGoBack: "",
      id: 0,
      error: "",
      shown: "login",
      register: false,
      redirect: "none",
    };

    this.createProfileClick = this.createProfileClick.bind(this);
  }

  loginForm(e, define) {
    if (define === "uname") {
      this.loginInfo.email = e.target.value;
      if (e.target.value !== "") {
        this.setState({ emailHasValue: true });
      } else {
        this.setState({ emailHasValue: false });
      }
    }
    if (define === "psw") {
      this.loginInfo.psw = e.target.value;

      if (e.target.value !== "") {
        this.setState({ passwordHasValue: true });
      } else {
        this.setState({ passwordHasValue: false });
      }
    }
  }

  login(e) {
    this.setState({ loading: true });

    this.axios.post(env.protocol + env.env + "/api/public/login.php", this.loginInfo).then((response) => {
      if (response.data.loginSuccess) {
        sessionStorage.setItem("vfiUser", response.data.id);
        this.setState({ loading: false });
        this.props.loginSuccess();
      } else {
        this.setState({ loading: false });

        if (response.data.error === "incorrect_email") {
          this.setState({ error: "Email address not found" });
        } else if (response.data.error === "incorrect_password") {
          this.setState({ error: "Incorrect password" });
        } else {
          this.setState({ error: "An unexpected error occurred. Please try again." });
        }
      }
    });
  }

  responseFacebook = (response) => {
    this.loginInfo.email = response.email;
    this.loginInfo.facebookLogin = 1;
    this.loginInfo.psw = "";
    this.loginInfo.img = response.picture.data.url;

    let e = response;

    axios
      .post(env.protocol + env.env + "/api/public/facebookAuth.php", {
        access_token: e.accessToken,
      }) //userData)
      .then((response) => {
        var data = response.data;

        if (response.data.loginSuccess) {
          sessionStorage.setItem("vfiUser", data.id);

          this.props.loginSuccess();
        }
      });
  };

  createProfileClick() {
    useRouterStore.getState().navByLink("register");
  }

  createWhatsonEvent = () => {
    return;

    window.location.hash = "/register/createEvent";
    window.history.pushState(null, "", "createEvent");
    this.setState({
      navTo: "register",
      redirect: "Event",
    });
  };

  render() {
    const rememberChecked = this.state.rememberMe ? "checked" : "";
    switch (this.state.navTo) {
      case "newPassword":
        return <NewPassword hash={this.state.hash} email={this.state.email} />;
      case "create.Event":
      case "create.Place":
      case "create.Tour":
        return (
          <CreateWhatson
            navTo={this.state.navTo.split(".")[1]}
            urlToGoBack={this.state.urlToGoBack}
            setNav={(e) => {
              this.setState({ navTo: e });
            }}
            redirect={this.state.redirect}
            register={this.state.register}
          />
        );
      case "register":
        return (
          <CreateWhatson
            navTo={"register"}
            urlToGoBack={this.state.urlToGoBack}
            setNav={(e) => {
              this.setState({ navTo: e });
            }}
            redirect={this.state.redirect}
            register={this.state.register}
          />
        );
      case "login":
        return (
          <LoginOrRegister
            navTo={"login"}
            redirect={this.state.redirect}
            register={this.state.redirect}
            loginSuccess={this.props.loginSuccess}
          />
        );
      case "submit.Event":
      case "submit.Place":
      case "submit.Tour":
        return (
          <SubmitChange
            navTo={this.state.navTo.split(".")[1]}
            id={this.state.id}
            urlToGoBack={this.state.urlToGoBack}
          />
        );
      default:
        return (
          <div className={env.env.includes("vita.fo") ? "vita login-contents" : "login-contents"}>
            <div className="content col-sm-6">
              <div className={"imgcontainer"}>
                <img className="vfi-logo" src={env.env.includes("vita.fo") ? vitaLogo : vfiLogo} alt="vfi-logo" />

                <span className="vfi-text">
                  {env.env.includes("vita.fo")
                    ? [
                        <span>
                          <img className="vita-logo" src={vitaLogo} alt="vita-logo" />
                        </span>,
                        <span className="dash">|</span>,
                        <span className="type">CMS</span>,
                      ]
                    : "What’s On"}
                </span>
              </div>
              {this.state.shown === "login" && (
                <div id="Login">
                  {this.props.topMessage && <div className="top-message">{this.props.topMessage}</div>}
                  {this.state.error !== "" && <div className="error-message">{this.state.error}</div>}
                  <div className="wrap-container">
                    <div className="container">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          this.login();
                        }}
                      >
                        <input
                          className={this.state.emailHasValue ? "" : "no-value"}
                          type="email"
                          placeholder="Email"
                          name="uname"
                          onChange={(e) => {
                            this.loginForm(e, "uname");
                          }}
                          required
                        />
                        <input
                          className={"password " + (this.state.passwordHasValue ? "" : "no-value")}
                          type={!this.state.showPassword ? "password" : "text"}
                          placeholder="Password"
                          name="psw"
                          onChange={(e) => {
                            this.loginForm(e, "psw");
                          }}
                          required
                        />
                        <div className="show-password-container">
                          <label>
                            <VfiCheckbox
                              checked={this.state.showPassword}
                              onChange={() => {
                                this.setState({ showPassword: !this.state.showPassword });
                              }}
                            />
                            <span className={"show-password " + rememberChecked}>Show password</span>
                          </label>
                        </div>
                        <button
                          onClick={() => {
                            this.login();
                          }}
                          type="submit"
                        >
                          {this.state.loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Login"}
                        </button>
                      </form>
                      <div
                        className="cannot-login"
                        onClick={() => {
                          useRouterStore.getState().navByLink("forgot_password");
                        }}
                      >
                        Can't log in?
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.shown === "forgotPsw" && <ForgotPassword goBack={() => this.setState({ shown: "login" })} />}
              {this.state.shown === "newsletter_unsubscribe" && <NewsletterUnsubscribe token={this.state.token} />}
            </div>
            <div className="create-profile" onClick={this.createProfileClick}>
              <span className="text">DON’T HAVE A USER? REGISTER HERE</span>
              <FontAwesomeIcon icon={faUser} />
            </div>
            {(window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") && [
              <div key={"create-event"} className="create-event" onClick={this.createWhatsonEvent}>
                <span
                  className="text"
                  onClick={() => {
                    useRouterStore.getState().navByLink("submit/event/create");
                  }}
                >
                  CREATE WHATSON EVENT
                </span>
                <FontAwesomeIcon icon={faUser} />
              </div>,
            ]}
          </div>
        );
    }
  }
}

export default Login;
